<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="500" overlay-opacity="1" v-if="resolutionScreen >= 500">
      <v-card>
        <!-- <v-card-title><v-icon large :color="filedata.file_icon[1]" class="pb-2 pr-2">{{ filedata.file_icon[0] }}</v-icon>{{filedata.file_name}}</v-card-title> -->
        <v-card-title
          >{{ $t("checkpassword.inputpassword") }} <v-spacer></v-spacer>
          <v-icon color="red" class="pb-2">lock</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <v-layout row wrap align-center justify-center>
            <v-flex xs11 lg11 ma-5>
              <v-card class="elevation-0">
                <v-layout row wrap justify-center>
                  <v-flex lg10 xs10>
                    <span class="mb-2">
                      {{ $t("checkpassword.check") }}&nbsp; {{filedata.file_name }}
                    </span>
                    <v-text-field
                      :label="$t('checkpassword.inputpassword')"
                      solo
                      hide-details
                      v-model="password"
                      persistent-hint
                      :disabled="processloading"
                      multiple
                      outlined
                      dense
                      @click:append="showpassword = !showpassword"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="closedialog()" :disabled="processloading">{{ $t("checkpassword.cancel") }}</v-btn>
          <v-btn
            dark
            :color="color.theme"
            @click="checkgotodirectory(), (password = ''), (showpassword = false)"
            :loading="processloading"
            >{{ $t("checkpassword.ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="show"
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      overlay-opacity="1"
      v-else
    >
      <v-card>
        <v-form class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <v-icon color="red" class="pb-2">lock</v-icon
              ><span :style="headerPage">{{
                $t("checkpassword.inputpassword")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                @click="closedialog(), (password = '')"
                :color="color.theme"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText"
            >{{ $t("checkpassword.check") }}&nbsp;
            {{ filedata.file_name }}</span
          >
          <v-text-field
            class="mt-1 mb-n2 textFieldClass"
            :color="color.theme"
            outlined
            dense
            v-model="password"
            :type="showpassword ? 'text' : 'password'"
            :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
            height="35px"
            @click:append="showpassword = !showpassword"
            autocomplete="off"
            persistent-hint
            :disabled="processloading"
            :label="$t('checkpassword.inputpassword')"
          ></v-text-field>
          <div class="text-center">
            <v-btn
              :style="btnAction"
              class="mr-6"
              height="40px"
              width="45%"
              color="red"
              outlined
              @click="closedialog(), (password = '')"
              >{{ $t("checkpassword.cancel") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              height="40px"
              width="45%"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="
                checkgotodirectory(), (password = ''), (showpassword = false)
              "
              >{{ $t("checkpassword.ok") }}</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: function() {
    return {
      processloading: false,
      showpassword: false,
      password: "",
      file: [],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
  },
  props: ["show", "filedata", "parameter", "currentfile", "key_st"],
  methods: {
    closedialog() {
      if(this.key_st === "st"){
        this.$emit("close_c_star");
      }else{
        // this.$emit("closedialog");
      // this.$router.replace({ path: "/mydrive/"});
        this.$emit("closedialog"); // แก้กดยกเลิกใส่รหัสผ่านจากคลิ๊กขวา
        this.$emit("closepreview")
      }
    },
    async checkgotodirectory() {
      console.log("rigthclickcheckpassword");
      if (this.password !== "") {
        // if (this.filedata.file_type !== "folder") {
          this.processloading = true;
          let payload = {
          account_id: this.dataAccountId,
          data_id: this.filedata.file_id,
          data_type: this.filedata.file_type !== 'folder' ? "file" : "folder",
          password: this.password,
          };
          console.log("file", payload);
          let auth = await gbfGenerate.generateToken();

          this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/check/password", payload, {
              headers: { Authorization: auth.code },
            })
            .then((response) => {
              console.log("response", response);
              if (response.data.status === "OK") {
                this.processloading = false;
                console.log("this.key_st");
                if(this.key_st === "st"){
                 this.$emit("close_star");
                }
                this.$emit("closedialog", this.parameter, this.filedata);
              } else {
                this.processloading = false;
                Toast.fire({
                  icon: "error",
                  title: this.$t("checkpassword.passwordincorrect"),
                });
              }
            });
        // } else {
        //   let payload = {
        //     account_id: this.dataAccountId,
        //     folder_id: this.filedata.file_id,
        //     status: "",
        //     user_id: this.dataUsername,
        //     password: this.password,
        //   };
        //   console.log("folder", payload);
        //   let auth = await gbfGenerate.generateToken();
        //   this.axios
        //     .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/lock_file/search_file", payload, {
        //       headers: { Authorization: auth.code },
        //     })
        //     .then((response) => {
        //       if (response.data.status === "OK") {
        //         this.processloading = false;
        //         this.$emit("closedialog", this.parameter, this.filedata);
        //       } else {
        //         this.processloading = false;
        //         Toast.fire({
        //           icon: "error",
        //           title: "คุณใส่รหัสผิด",
        //         });
        //       }
        //     });
        // }
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("checkpassword.enterpassword"),
        });
      }
    },
  },
};
</script>
<style>
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-input.textFieldClass input {
  font-size: 14px;
}
.v-input.textFieldClass input {
  font-size: 14px;
  max-height: 90px;
  padding-bottom: 11px;
  padding-top: 11px;
}
</style>